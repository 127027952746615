import './newBadge.css'

const NewBadge = () => {
    return (
        <div className="new_badge">
            <div className="new_badge_content">
                <div className="new_badge_label">New</div>
            </div>
        </div>
    )
}

export default NewBadge
